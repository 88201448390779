﻿$white: #fff !default;
$grayLighter: #EFF1F0 !default;
$grayLight: #C6CDCF !default;
$grayDark: #82919B !default;
$grayDarker: #444E53 !default;
$black: #000 !default;

$blue: #005ABB !default;
$red: #CD3A3A !default;
$yellow: #EFEB85 !default;
$pink: #FFDFF3 !default;
$lightBlue: #EEF6FF !default;

$primary: #005ABB !default;

$onlineColor: #4CC947 !default;
$offlineColor: $red !default;

$primaryIconColor: $grayDarker !default;
$timelineColor: $grayDarker !default;
$titleColor: $grayDarker !default;
$textPrimaryColor: $grayDarker !default;
$predictiveEventsColor: $grayDarker !default;

$markerPrimary: #226C9E !default;

$activeRoadColor: $grayDark !default;
$secondaryIconColor: $grayDark !default;
$textSecondaryColor: $grayDark !default;
$closedEventColor: $grayDark !default;

$unactiveRoadColor: $grayLight !default;
$filterButtonColor: $grayLight !default;

$secondaryRoadColor: $grayLighter !default;
$layoutBackgroundColor: $grayLighter !default;
$mainBackgroundColor: $white !default;

$eventColor: $blue !default;
$iconActiveColor: $blue !default;
$brandColor: $blue !default;

$alarmColor: $red !default;
$notificationColor: $red !default;
$alertColor: $red !default;

$panelColor: $yellow !default;

$cctvColor: $pink !default;

$selectColor: $lightBlue !default;

$backgroundPMVPreview: #222222 !default;
$textsPMVPreview: #FFD202 !default;
$squarePMVPreview: #A78C23 !default;

// Theme agnostic variables
$rootFontSize: 16;
$maxWidth: remy(8000);
$headerHeight: remy(86);
$eventsHeaderHeight: remy(170);
$navbarWidth: remy(86);
$navbarIconSize: remy(20);
/*$logoImgWidth: remy(70);*/
$logoWidth: remy(60);
$headerIconsSize: remy(24);
$supportIconWidth: remy(24);
$userInfoIconWidth: remy(40);
$mainHorizontalSpacing: remy(30);
$mainVerticalSpacing: remy(30);
$minorSpacing: remy(8);
$searchBoxHeight: remy(30);
$scrollbarWidth: 10px;
/*$scrollbarWidth: remy(5);*/
$scrollbarColor: $grayLight;
$searchHeight: remy(260);
$alarmToastWidth: remy(520);
$alarmToastHeight: remy(120);
$alarmToastClosePadding: remy(17);
$alarmToastIconContainerSize: remy(30);
$alarmToastIconSize: remy(26);
$formFooterHeight: remy(130);
$innerPanelPadding: remy(20);

$buttonPrimaryColor: $blue !default;
$buttonSecondaryColor: $grayDarker !default;
$buttonNegativeColor: #C7C7C7 !default;
$buttonDisabledColor: $grayDark !default;

$timelineTopLabelHeight: remy(15);
$timelineHeight: remy(100);

// homepage timeline
$timelineHeaderHeight: remy(70);
$timelineDateHeight: remy(16);
$timelineDateBottomPadding: remy(16);
$timelineContainerPadding: remy(25);
$circleSize: remy(8);
$eventsTopValue: remy(40);
$selectedEventIconColor: $blue !default;
$unselectedEventIconColor: $white !default;
$carouselContainerPaddingTop: remy(15);
$negativeCarouselContainerPaddingTop: remy(-15);
$closedTimelineEventColor: #C5CCCE !default;
$scheduledTimelineEventColor: #7FACDD !default;
$openedTimelineEventLastColor: $blue !default;

// pmv
$panelErrorColor: $red !default;
$pageViewModeHeight: remy(55);
$sideIndicatorWidth: remy(15);
$pmvCardIDCElementsPaddingLeft: remy(9);
$pmvListBorderRadius: remy(24);
$priorityBadgeBorderColor: $markerPrimary !default;

// pmv details carousel
$pmvDetailsMainTitleColor: $grayDark !default;
$pmvDetailsTagsBorderColor: $grayLight !default;
$pmvDetailsErrorColor: $red !default;
$pmvDetailsHoverColor: $grayLighter !default;
$pmvDetailsTagsContentColor: $grayDarker !default;
$pmvCourtesyMessagesColor: $grayDark !default;

$button-types: (
    primary: (
        background-color: transparent,
        border-color: $buttonPrimaryColor,
        text-color: $buttonPrimaryColor,
        hover-background-color: $buttonPrimaryColor,
        hover-text-color: $mainBackgroundColor,
        hover-border-color: $buttonPrimaryColor
    ),
    secondary: (
        background-color: transparent,
        border-color: $buttonSecondaryColor,
        text-color: $buttonSecondaryColor,
        hover-background-color: $buttonSecondaryColor,
        hover-text-color: $mainBackgroundColor,
        hover-border-color: $buttonSecondaryColor
    ),
    negative: (
        background-color: $buttonNegativeColor,
        border-color: $mainBackgroundColor,
        text-color: $mainBackgroundColor,
        hover-background-color: rgba(255,255,255,0.3),
        hover-text-color: $mainBackgroundColor,
        hover-border-color: $mainBackgroundColor
    ),
    disabled: (
        disabled-color: $buttonDisabledColor
    )
);

$formElementSpacing: remy(8);

$collapsedBottomPanelHeight: remy(81);
$eventDetailHeaderMainInfoHeight: remy(87);

$mainBorderRadius: remy(20);

$widthPopOverTimeline: remy(252);
$heightPopOverTimeline: remy(165);

$minimapHeight: remy(150);
$minimapWidth: remy(312);
$minimapControlsHeight: remy(43);
$appliedBookmarkBarHeight: remy(55);
$heightPopOverTimeline: remy(165);
$sidebarFooterHeight: remy(80);
$sidebarFooterAlarmHeight: remy(120);

$PMVTopPanelHeight: remy(320);
$PMVBottomStickFooterHeight: remy(98);

/* custom */

$yellowWarning: #FFC107;

/* custom navmenu */

$navm_bgcolor: #EFF1F0;
$navm_color: $blue;
$accn_bgcolor: #EEF6FF;
$navm_color: $blue;

/* end */

/* end */
